class tics {
  constructor(siteID, opts) {
    const options = opts || {};

    this.siteID = siteID;
    //    this.sessionID;
    this.quietMode = !(options.logs);
    this.anonymous = !!(options.anonymous);
    this.newVisitor = false;
    this.referrer = document.referrer;

    this.init();
  }

  init() {
    this.handleSession();
  }

  handleSession() {
    if (!this.anonymous) {
      if (sessionStorage.sessionID === undefined) {
        sessionStorage.sessionID = new Date().getTime() + parseInt(Math.random() * 100, 10);
        this.sessionID = sessionStorage.sessionID;
        this.log(`SessionID created : ${this.sessionID}`);

        this.handleCampain();
      } else {
        this.sessionID = sessionStorage.sessionID;
        this.log(`Session ID found : ${this.sessionID}`);
      }
    } else {
      this.sessionID = this.siteID;
      this.log(`SessionID anonymoused : ${this.sessionID}`);

      this.handleCampain();
    }

    this.newVisitor = this.isNewVisitor();

    this.pageView();
  }

  handleCampain() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const campain = urlParams.get('campain');

    this.log(campain);

    if (campain) {
      this.campain(campain);
    }
  }

  isNewVisitor() {
    let newVisitor = true;

    if (!this.anonymous) {
      if (localStorage.lastSessionID === undefined) {
        this.log('This is a new visitor');
        newVisitor = true;
        localStorage.lastSessionID = this.sessionID;
      } else {
        this.log('This is a returning visitor');
        newVisitor = false;
      }
    }

    return newVisitor;
  }

  pageView() {
    const self = this;

    this.ajax({
      method: 'POST',
      url: 'https://api.get-tics.com/pageview',
      data: {
        siteID: this.siteID,
        sessionID: this.sessionID,
        url: window.location.pathname,
        useragent: navigator.userAgent,
        newVisitor: this.newVisitor,
        referrer: this.referrer,
      },
      success(r) {
        self.log(r);
      },
    });
  }

  event(event) {
    const self = this;

    this.ajax({
      method: 'POST',
      url: 'https://api.get-tics.com/event',
      data: {
        siteID: this.siteID,
        sessionID: this.sessionID,
        url: window.location.pathname,
        event,
      },
      success(r) {
        self.log(r);
      },
    });
  }

  campain(campainID) {
    const self = this;

    this.ajax({
      method: 'POST',
      url: 'https://api.get-tics.com/campaign',
      data: {
        siteID: this.siteID,
        sessionID: this.sessionID,
        url: window.location.pathname,
        campainID,
      },
      success(r) {
        self.log(r);
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  ajax(params) {
    const xhr = new XMLHttpRequest();
    xhr.open(params.method, params.url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function () {
      let response;

      if (xhr.status === 200) {
        response = JSON.parse(xhr.responseText);

        if (params.success) {
          params.success(response);
        }
      } else if (xhr.status !== 200) {
        response = { status: 'error', code: xhr.status };

        if (params.error) {
          params.error(response);
        }
      }
    };

    xhr.send(JSON.stringify(params.data));
  }

  log(stuffToLog) {
    if (!this.quietMode) {
      console.log(stuffToLog);
    }
  }
}
module.exports = tics;
