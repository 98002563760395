<template>
  <v-card
    class="mb-12"
  >
    <v-card-title class="d-flex justify-center nobreak">
      Pour terminer votre inscription
    </v-card-title>

    <v-card-text class="d-flex justify-center">
      <v-row>
        <v-col
          cols="12"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="contact.lastName"
              label="Nom"
              :rules="[v => !!v || 'Le nom est requis']"
              prepend-icon="mdi-account"
            />
            <v-text-field
              v-model="contact.firstName"
              label="Prenom"
              :rules="[v => !!v || 'Le prénom est requis']"
              prepend-icon="mdi-account"
            />
            <v-text-field
              v-model="contact.email"
              label="Adresse e-mail"
              type="email"
              :rules="emailRules"
              prepend-icon="mdi-mail"
            />
            <v-text-field
              v-model="contact.phone"
              type="tel"
              label="Téléphone"
              :rules="phoneRules"
              prepend-icon="mdi-phone"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <div class="d-flex justify-center">
      <v-btn
        color="primary"
        block
        @click="sendForm"
      >
        Terminer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'StepperStep5',
  props: {
    interests: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    valid: true,
    emailRules: [
      (v) => !!v || 'L\'E-mail est requis',
      (v) => /.+@.+\..+/.test(v) || 'L\'E-mail doit être valide',
    ],
    phoneRules: [
      (v) => !!v || 'Le numéro de téléphone est requis',
      (v) => (v && v.length <= 10) || 'Numéro de téléphone trop court',
    ],
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',

    },
  }),
  methods: {
    sendForm() {
      if (this.$refs.form.validate()) {
        this.$emit('sendForm', this.contact);
      }
    },
    validate() {
    },
  },
};
</script>

<style>

</style>
