<template>
  <v-card
    class="mb-12"
  >
    <v-card-title class="d-flex justify-center nobreak">
      Avez-vous travaillé en France pendant plus de deux ans ?
    </v-card-title>
    <v-card-text class="d-flex justify-center">
      <h3 />
    </v-card-text>
    <v-card-text class="d-flex flex-column">
      <v-btn
        class="my-3"
        block
        large
        :color="infos.anciennete ? 'green' : ''"
        @click="$emit('formUpdate',{anciennete: true, step: 2})"
      >
        Oui
      </v-btn>
      <v-btn
        class="my-3"
        block
        large
        @click="$emit('formUpdate',{anciennete: false, step: 7})"
      >
        Non
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StepperStep1',
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
.nobreak{
  word-break: normal;
}

</style>
