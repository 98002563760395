<template>
  <div>
    <div v-if="step < 6">
      <v-stepper
        v-model="step"
        vertical
      >
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          Dites-nous en plus sur vous...
        </v-stepper-step>
        <v-stepper-content step="1">
          <stepper-step-1
            :infos="infos"
            @formUpdate="updateForm"
          />
        </v-stepper-content>

        <v-stepper-step
          :complete="step > 2"
          step="2"
        />
        <v-stepper-content step="2">
          <StepperStep2
            :infos="infos"
            @formUpdate="updateForm"
          />
        </v-stepper-content>

        <v-stepper-step
          step="3"
          :complete="step > 3"
        />

        <v-stepper-content step="3">
          <StepperStep3
            :infos="infos"
            @formUpdate="updateForm"
          />
        </v-stepper-content>
        <v-stepper-step
          step="4"
          :complete="step > 4"
        />
        <v-stepper-content step="4">
          <StepperStep4
            :interests="infos.interests"
            @formUpdate="updateForm"
          />
        </v-stepper-content>
        <v-stepper-step
          step="5"
          :complete="step > 5"
        />
        <v-stepper-content step="5">
          <StepperStep5
            @formUpdate="updateForm"
            @sendForm="sendForm"
          />
        </v-stepper-content>

        <div class="text-center mt-3">
          <v-btn
            icon
            color="primary"
            outlined
            @click="prev"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            color="primary"
            icon
            @click="next"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-stepper>
    </div>
    <div
      v-else-if="step === 6"
      class="d-flex justify-center mt-5"
    >
      <v-card
        width="400px"
      >
        <v-card-title>
          Merci
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          Merci pour votre inscription, un de nos conseillers pédagogique va vous recontacter rapidement !
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="success"
            @click="goToSite"
          >
            Visiter notre site
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div
      v-else
      class="d-flex justify-center mt-5"
    >
      <v-card
        width="400px"
      >
        <v-card-title>
          Désolé :(
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          Malheureusement vous n'êtes pas éligible à la formation prise en charge. Vous pouvez tout de même consulter notre catalogue.
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            color="info"
            @click="step = 1"
          >
            Recommencer
          </v-btn>
          <v-btn
            color="success"
            @click="goToSite"
          >
            Voir le catalogue
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import StepperStep1 from '@/components/StepperStep1.vue';
import StepperStep2 from '@/components/StepperStep2.vue';
import StepperStep3 from '@/components/StepperStep3.vue';
import StepperStep4 from '@/components/StepperStep4.vue';
import StepperStep5 from '@/components/StepperStep5.vue';

export default {
  name: 'StepperWrapper',
  components: {
    StepperStep1,
    StepperStep2,
    StepperStep3,
    StepperStep4,
    StepperStep5,
  },
  props: {
    t: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    step: 1,
    infos: {
      anciennete: null,
      work: '',
      work2014: null,
      employment: '',
      interests: [],
    },
  }),
  watch: {
    step(val) {
      this.t.event(`step-${val}`);
    },
  },
  methods: {
    updateForm(payload) {
      this.step = payload.step;
      if (payload.interests) {
        const index = this.infos.interests.indexOf(payload.interests);
        if (index === -1) {
          this.infos.interests.push(payload.interests);
        } else {
          this.infos.interests.splice(index, 1);
        }
      } else {
        Object.keys(this.infos).forEach((key) => {
          if (key in payload) {
            this.infos[key] = payload[key];
          }
        });
      }
    },
    sendForm(payload) {
      let interests = '';
      this.infos.interests.forEach((interet) => {
        interests += interet;
      });
      const lead = {
        lastName: payload.lastName,
        firstName: payload.firstName,
        email: payload.email,
        phone: payload.phone,
        worked2years: '> 2 ans',
        work: this.infos.work,
        interests,
      };
      this.$http.post(`${process.env.VUE_APP_API_URL}/leads`, lead)
        .then(() => {
          this.step = 6;
          this.t.event('formsent');
          this.$gtm.trackEvent({
            event: 'formsent',
            action: 'click',
          });
        })
        .catch((err) => console.log(err))
        .finally();
    },
    goToSite() {
      window.open('https://laformationpro.fr', '_blank');
    },
    prev() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    next() {
      if (this.step < 5) {
        this.step += 1;
      }
    },
  },

};
</script>

<style>

</style>
