<template>
  <v-card
    class="mb-12"
  >
    <v-card-title class="d-flex justify-center">
      Vous travaillez :
    </v-card-title>
    <v-card-text class="d-flex flex-column">
      <v-btn
        class="my-3"
        block
        :color="infos.work ==='public' ? 'green' : ''"
        @click="$emit('formUpdate',{work: 'public', step: 2})"
      >
        Pour la fonction publique
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.work ==='private' ? 'green' : ''"
        @click="$emit('formUpdate',{work: 'private', step: 3})"
      >
        Pour une entreprise privée
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.work ==='other' ? 'green' : ''"
        @click="$emit('formUpdate',{work: 'other', step: 3})"
      >
        Autre
      </v-btn>
    </v-card-text>
    <div v-show="infos.work ==='public'">
      <v-card-title class="d-flex justify-center">
        Avez-vous travaillé dans le privé après 2014 ?
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-btn
          class="my-3"
          block
          :color="infos.work2014 ? 'green' : ''"
          @click="$emit('formUpdate',{work2014: true, step: 3})"
        >
          Oui
        </v-btn>
        <v-btn
          class="my-3"
          block
          :color="infos.work ==='private' ? 'green' : ''"
          @click="$emit('formUpdate',{work: 'public', step: 7})"
        >
          Non
        </v-btn>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'StepperStep2',
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
