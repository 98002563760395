<template>
  <v-card
    class="mb-12"
  >
    <v-card-title class="d-flex justify-center nobreak">
      Vous êtes intéressés par une formation de :
    </v-card-title>
    <v-card-text class="d-flex justify-space-around flex-wrap">
      <v-hover
        v-for="(card, index) in cards"
        v-slot="{ hover }"
        :key="index"
      >
        <v-card
          :class="isSelected(card.title) ? 'green-outline' : ''"
          :elevation="hover ? 12 : 2"
          class="ma-3"
          width="300px"
          @click="select(card.title)"
        >
          <v-img
            height="300px"
            width="300px"
            :src="card.src"
            aspect-ratio="1"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title v-text="card.title" />
          </v-img>

          <v-card-actions>
            <v-spacer />

            <v-btn
              v-if="isSelected(card.title)"
              icon
              color="green"
            >
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
            >
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </v-btn>

            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        color="primary"
        :disabled="interests.length === 0"
        block
        @click="$emit('formUpdate', {step: 5})"
      >
        Suivant
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

  <!-- eslint-disable global-require -->
<script>
export default {
  props: {
    interests: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    cards: [
      { title: 'Anglais', src: require('@/assets/english_flag.jpg') },
      { title: 'Informatique', src: require('@/assets/informatique.jpg') },
      { title: 'Design', src: require('@/assets/design.jpg') },
      { title: 'Autre', src: require('@/assets/autre.jpg') },
    ],

    //
  }),
  computed: {
  },
  methods: {
    isSelected(title) {
      if (this.interests.indexOf(title) === -1) {
        return false;
      }
      return true;
    },
    select(element) {
      this.$emit('formUpdate', { interests: element, step: 5 });
    },
  },

};
</script>

<style>
.green-outline{
  border: solid 2px #4CAF50 !important;
}
</style>
