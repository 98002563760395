<template>
  <v-card
    class="mb-12"
  >
    <v-card-title class="d-flex justify-center">
      Vous êtes :
    </v-card-title>

    <v-card-text class="d-flex flex-column">
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='salarie' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'salarie', step: 4})"
      >
        Salarié
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='public' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'public', step: 4})"
      >
        Fonctionnaire
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='student' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'student', step: 4})"
      >
        Étudiant
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='search' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'search', step: 4})"
      >
        En recherche d'emploi
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='retired' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'retired', step: 4})"
      >
        Retraité
      </v-btn>
      <v-btn
        class="my-3"
        block
        :color="infos.employment ==='other' ? 'green' : ''"
        @click="$emit('formUpdate',{employment: 'other', step: 4})"
      >
        Autre
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StepperStep3',
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>

</style>
