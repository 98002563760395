<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="La Formation Pro Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="La Formation Pro name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/white_logo_text_transparent_background.png"
          width="100"
        />
      </div>

      <v-spacer />

      <v-btn
        href="https://laformationpro.fr"
        target="_blank"
        text
      >
        <span class="mr-2">Visiter notre site</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-card
        v-show="!displayForm"
        class="mx-auto my-12"
        max-width="500"
      >
        <v-img
          height="250"
          src="./assets/formationadistance.jpg"
        />
        <v-card-title>
          Formulaire d'inscription
        </v-card-title>
        <v-card-text>
          Bienvenue sur le formulaire d'inscription de La Formation Pro.
        </v-card-text>
        <v-card-text>
          Nous proposons de nombreuses formations à distance en visio-conférence avec nos professeurs et <b>à votre rythme !</b>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            block
            @click="startForm"
          >
            En savoir plus
          </v-btn>
        </v-card-actions>
      </v-card>
      <StepperWrapper
        v-show="displayForm"
        :t="t"
      />
    </v-main>
  </v-app>
</template>

<script>
import StepperWrapper from './components/StepperWrapper.vue';

export default {
  name: 'App',

  components: {
    StepperWrapper,
  },

  data: () => ({
    displayForm: false,
    t: null,
    //
  }),
  mounted() {
    this.t = new this.$tics('9e3dccf9ab3');
  },
  methods: {
    startForm() {
      this.displayForm = true;
      this.t.event('Start');
    },
  },
  metaInfo: {
    title: 'Inscription',
    titleTemplate: '%s - La Formation Pro',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
};
</script>
